<template>
  <div>
    <el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="审批单号" prop="auditCode">
        <el-input v-model="form.auditCode" placeholder="请输入审批单号"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.customerPhone" type="textarea" placeholder="请输入手机号码(新增多个手机号码请以“,”分隔开)"></el-input>
      </el-form-item>
      <el-form-item label="券套餐" prop="pid">
        <el-select class="more-select" v-model="form.pid" placeholder="请选择套餐券" style="width:255px;" @change="pidChange">
          <el-option v-for="(item,index) in vouchersList" :key="index" :label="item.packageName" :value="item.pid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="券套餐清单">
        <div class="content-text" v-for="(item,index) in vouchersDetailList" :key="index" v-text="item.vouchersTitle + ' * '+item.vouchersNumber"></div>
      </el-form-item>
      <el-form-item label="备注说明">
        <el-input style="width:100%;" placeholder="中英文、字母或数字组合，长度小于300字" type="textarea" v-model="form.remark" maxlength="300"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" v-debounce="onSubmit">立即生成</el-button>
    <el-button @click="dialogClose">取消</el-button>
  </div>
</template>
<script>

import { getPackageList, vouchersPackageIssue } from '@/api/setting';
export default {
  props: ['isAddDialog'],
  data() {

    return {
      vouchersList: [],
      vouchersDetailList: [],
      form: {
        pid: '',
        customerPhone: '',
        remark: '',
        title: '',
        auditCode: ''
      },
      rules: {
        pid: [
          { required: true, message: '请选择折扣券', trigger: 'change' },
        ],
        title: [
          { required: true, message: '标题不可为空', trigger: 'blur' },
        ],
        auditCode: [{ required: true, message: "审批单号不可为空", trigger: "blur" }],

      },
    }
  },
  watch: {
    isAddDialog(n, o) {
      if (n) {
        this.form.customerPhone = '';
        this.form.remark = '';
      }
    }
  },
  mounted() {
    this.getVouchersList();
  },
  methods: {
    pidChange() {
      this.vouchersDetailList = this.vouchersList.filter(v => v.pid == this.form.pid)[0]['infoList']
    },
    getVouchersList() {
      let that = this;
      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 1000,
      };
      getPackageList(param)
        .then((response) => {
          if (response.code == 200) {
            that.vouchersList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },

    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(formName = 'form') {

      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));
      this.$refs['form'].validate((valid) => {
        if (valid) {
          vouchersPackageIssue(form)
            .then((response) => {
              if (response.code == 200) {
                that.dialogClose();
              }
              that.$message({
                title: '错误',
                type: response.code == 200 ? 'success' : 'error',
                message: response.msg || response.error_msg || response.message,
                duration: 1500
              });

            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })


    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
</style>