<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="审批单号" prop="auditCode">
        <el-input v-model="form.auditCode" placeholder="请输入审批单号"></el-input>
      </el-form-item>
      <el-form-item label="客户名称" prop="customerName">
        <el-input v-model="form.customerName" maxlength="16" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="customerPhone">
        <el-input v-model.number="form.customerPhone" maxlength="11" placeholder="请输入电话(7-11位数字)"></el-input>
      </el-form-item>
      <el-form-item label="券套餐" prop="pid">
        <el-select class="more-select" v-model="form.pid" placeholder="请选择套餐券" style="width:255px;">
          <el-option v-for="(item,index) in vouchersList" :key="index" :label="item.packageName" :value="item.pid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="券套餐清单">
        <div class="content-text" v-for="(item,index) in vouchersDetailList" :key="index" v-text="item.vouchersTitle + ' * '+item.vouchersNumber"></div>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" v-debounce="onSubmit">立即生成</el-button>
      <el-button @click="dialogClose">取消</el-button>
    </div>
  </div>
</template>
<script>

import { getPackageList, vouchersPackageIssue } from '@/api/setting';
export default {
  props: ['isAddDialog'],
  data() {

    return {
      vouchersList: [],
      vouchersDetailList: [],
      form: {
        pid: '',
        customerPhone: '',
        customerName: '',
        title: '',
        auditCode: ''
      },
      rules: {
        pid: [
          { required: true, message: '请选择折扣券', trigger: 'change' },
        ],

        customerPhone: [
          { required: true, message: '手机号码不可为空', trigger: 'blur' },
        ],
        customerName: [
          { required: true, message: '手机号码不可为空', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '标题不可为空', trigger: 'blur' },
        ],
        auditCode: [{ required: true, message: "审批单号不可为空", trigger: "blur" }],
      },
    }
  },
  watch: {
    form: {
      handler(newArr) {
        console.log('newArr')
        console.log(newArr)
        this.vouchersDetailList = this.vouchersList.filter(v => v.pid == newArr.pid)[0]['infoList']
        console.log(this.vouchersDetailList)
      },
      deep: true,
      immediate: true
    },
    isAddDialog(n, o) {
      if (n) {
        this.form.customerPhone = '';
        this.form.customerName = '';
      }
    }
  },
  mounted() {
    this.getVouchersList();
  },
  methods: {

    getVouchersList() {
      let that = this;
      let param = {
        isPage: 0,
        pageNum: 1,
        pageSize: 100000000,
      };
      getPackageList(param)
        .then((response) => {
          if (response.code == 200) {
            that.vouchersList = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/^(0+)|[^\d]+/g, '');
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit(formName = 'form') {

      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));
      this.$refs['form'].validate((valid) => {
        if (valid) {
          vouchersPackageIssue(form)
            .then((response) => {
              if (response.code == 200) {
                that.dialogClose();
              }
              that.$message({
                title: '错误',
                type: response.code == 200 ? 'success' : 'error',
                message: response.msg || response.error_msg || response.message,
                duration: 1500
              });

            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查重试',
            duration: 1500
          });
          return false;
        }
      })


    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>