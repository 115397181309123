
<template>
  <div>
    <el-form label-width="100px" size="mini" inline>
      <el-row>
        <el-col :span="22">
          <el-form-item label="审批状态：">
            <el-select size="mini" style="width:120px;" v-model="search.auditStatus" placeholder="选择审批状态">
              <el-option label="未提交" value="0"></el-option>
              <el-option label="审批中" value="1"></el-option>
              <el-option label="审批通过" value="2"></el-option>
              <el-option label="退回" value="3"></el-option>
              <el-option label="撤回" value="4"></el-option>
            </el-select>
          </el-form-item>
          <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
          <el-form-item label="提交时间：">
            <el-date-picker style="width:320px;" v-model="search.submitTime" size="mini" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label=" ">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" style="width:100px;float:right;margin:6px 10px;" icon="el-icon-plus" @click.native="isSingleAddDialog = true" type="primary">单个发放</el-button>
          <el-button size="mini" style="width:100px;float:right;margin:6px 10px;" icon="el-icon-plus" @click.native="isBatchImportDialog = true" type="primary">批量发放</el-button>
        </el-col>
        <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 300px)">
          <el-table-column align="center" label="序号" width="50">
            <template slot-scope="scope">
              <div>{{scope.$index+1}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="submitByName" align="center" label="提交人" width="130"></el-table-column>
          <el-table-column prop="submitOrgName" align="center" label="提交机构" width="220"></el-table-column>
          <el-table-column prop="title" align="center" label="标题" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="auditCode" align="center" label="审批单号" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="submitTime" align="center" label="提交时间" width="100"></el-table-column>
          <el-table-column prop="packageAmount" align="center" label="总金额（元）" width="110">
            <template slot-scope="scope">
              <div>{{Number(scope.row.packageAmount)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="packageNumber" align="center" label="客户数量（人）" width="120"></el-table-column>
          <el-table-column prop="isUse" align="center" label="审批状态" width="80">
            <template slot-scope="scope">
              <el-tag class="el-tag-style" size="mini" disable-transitions :type="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus] && auditStatusTag[scope.row.auditStatus].text"></el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="approverName" align="center" label="审批人" width="110"></el-table-column>
          <el-table-column prop="approvalTime" align="center" label="审批时间" width="100"></el-table-column>
          <el-table-column prop="address" label="操作" align="center">
            <template slot-scope="scope">
              <i class="el-icon-view btn-hover" @click="editDetail(scope.row.batchId,1)">查看</i>
              <i class="el-icon-refresh-left btn-hover" @click="recall(scope.row.batchId)" v-if="[1].indexOf(scope.row.auditStatus) != -1">撤回</i>
              <i class="el-icon-circle-check btn-hover" @click="submitApproval(scope.row.batchId)" v-if="[0,3].indexOf(scope.row.auditStatus) != -1">提交</i>
              <!-- <a v-if="scope.row.auditStatus == 2" style="text-decoration:none;color:#000" :href="url + '/wlynVouchersPackageIssue/exportExcel?batchId='+scope.row.batchId+'&batchType='+ scope.row.batchType">
                <el-button icon="el-icon-folder-opened" type="text">导出</el-button>
              </a> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total"></el-pagination>
        </div>
      </el-row>
    </el-form>
    <el-dialog title="发送多个期油券" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="840px">
      <AddBatchCode :isAddDialog.sync="isAddDialog" v-if="isAddDialog"></AddBatchCode>
    </el-dialog>
    <el-dialog title="发送单个期油券" :visible.sync="isSingleAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="650px">
      <AddSingleCode :isAddDialog.sync="isSingleAddDialog" v-if="isSingleAddDialog"></AddSingleCode>
    </el-dialog>
    <el-dialog :destroy-on-close="true" :title="isEditDialogTitle[Number(editType)-1]" :visible.sync="isEditDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px">
      <EditBatch v-if="isEditDialog" :isAddDialog.sync="isEditDialog" :editId.sync="editId" :editType.sync="editType"></EditBatch>
    </el-dialog>
    <el-dialog title="批量导入期油券套餐" :visible.sync="isBatchImportDialog" :lock-scroll="false" :close-on-click-modal="false" width="840px">
      <BatchImportCode :isAddDialog.sync="isBatchImportDialog" v-if="isBatchImportDialog"></BatchImportCode>
    </el-dialog>
  </div>
</template>
<script>
import { queryBatchList, withdraw, submitApproval } from '@/api/codes'
import { organizationList } from '@/api/setting';
import { getCookies, auditStatusTag } from '@/utils/utils'
import { baseURL } from '@/utils/http'

import OrganizationSelect from '@/components/OrganizationSelect.vue'
import BatchImportCode from './batchImportCode.vue'
import AddBatchCode from './addBatchCode.vue'
import AddSingleCode from './addSingleCode.vue'
import EditBatch from './editBatch.vue'

export default {

  components: {
    BatchImportCode,
    AddBatchCode,
    AddSingleCode,
    EditBatch,
    OrganizationSelect
  },
  data() {
    return {

      auditStatusTag: auditStatusTag,
      url: baseURL,
      search: {
        submitOrg: '',
        auditStatus: '',
        approvalTime: '',
        submitTime: '',
        userName: '',
        amountStart: '',
        amountEnd: '',
      },

      // dialog
      isBatchImportDialog: false,
      isAddDialog: false,
      isSingleAddDialog: false,
      isEditDialogTitle: ['查看批次详情', '编辑批次详情', '提交批次详情'],
      isEditDialog: false,
      editId: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isBatchImportDialog() {
      this.getList();
    },
    isSingleAddDialog() {
      this.getList();
    },
    isAddDialog() {
      this.getList();
    },
    isEditDialog() {
      this.getList();
    },

  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList();
  },
  methods: {
    submitApproval(id) {
      let that = this;

      submitApproval({ batchId: id, submitBy: getCookies('sysId') })
        .then((response) => {
          if (response.code == 200) {
            that.getList();
          }
          that.$message({
            title: '错误',
            type: response.code == 200 ? 'success' : 'error',
            message: response.msg || response.error_msg || response.message,
            duration: 1500
          });
        })
    },
    recall(id) {
      let that = this;
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        withdraw(id)
          .then((response) => {
            that.$message({
              title: '错误',
              type: response.code == 200 ? 'success' : 'error',
              message: response.msg || response.error_msg || response.message,
              duration: 1500
            });
            that.getList();
          })
      })
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isEditDialog = true;
    },
    changeCheck(checkKey) {
      this.$nextTick(() => {
        this[checkKey] = this[checkKey].replace(/[^\w\.\/]/ig, '')
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },

    getList() {
      let that = this;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        batchType: 7,
      };
      let search = that.search;
      param['submitOrg'] = search.submitOrg;
      param['auditStatus'] = search.auditStatus;
      param['amountStart'] = search.amountStart;
      param['amountEnd'] = search.amountEnd;
      param['submitStartTime'] = search.submitTime[0];
      param['submitEndTime'] = search.submitTime[1];
      param['approvalStartTime'] = search.approvalTime[0];
      param['approvalEndTime'] = search.approvalTime[1];

      queryBatchList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            that.list = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }
        })
    },
    clearSearch() {
      this.search = {
        submitOrg: getCookies('organizationId'),
        auditStatus: '',
        approvalTime: '',
        submitTime: '',
        userName: '',
        amountStart: '',
        amountEnd: '',
      };
      this.total = 0
      this.pageNum = 1
      this.getList()

    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.el-tag-style {
  width: 60px;
  text-align: center;
}
.form-item-box {
  float: left;
  height: 46px;
}
.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>